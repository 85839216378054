<template>
  <div class="about">
    <Navigationpage/>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigationpage from "@/components/Navigationpage.vue";

export default {
  name: "About",
  components: {
    Navigationpage,
  },
};
</script>
